@import url("https://use.typekit.net/byd7xov.css");


* {
   margin: 0;
   padding: 0;
   box-sizing: border-box
}

html {
   scroll-behavior: smooth;
}

body {
   font-family: amster, serif;
   font-weight: 400;
   font-style: normal;
   background-color: #c9a768;
   color: #725d36;
   font-size: 25px;
   line-height: 1.2;
}

a {
   text-decoration: none;
   transition: all 0.4s;
   color: inherit;
   position: relative;
}

a::after {
   position: absolute;
   content: '';
   transition: all 0.4s;
   bottom: -8px;
   left: 0;
   width: 0;
   height: 2px;
   background-color: #caa868;
}

a:hover::after {
   width: 100%;
}

a>img:hover {
   opacity: 0.8
}

img {
   max-width: 100%;
   height: auto;
   transition: all 0.4s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
   font-family: acumin-pro-condensed, sans-serif;
   font-weight: 400;
   font-style: normal;
}


//end layout
// navigation
.container-nav {
   background-color: #725d36;
   color: #fff;
   padding: 15px 0;
}

.navbar {
   width: 90%;
   margin: auto;
   display: flex;
   align-items: center;
   justify-content: space-between;
   font-family: amster, serif;
   font-weight: 300;
   font-style: italic;

   &__logo a::after {
      display: none;
   }

   &__ul {
      list-style: none;
      display: flex;

      li {
         margin: 0 20px;
      }
   }
}

.navigationBtn {
   position: absolute;
   right: 50px;
   top: 65px;
   height: 27px;
   width: 35px;
   cursor: pointer;
   z-index: 9;
   transition: opacity .25s ease;
   display: none;

   & span {
      background: #fff;
      left: 0;
      width: 100%;
      border: none;
      height: 5px;
      position: absolute;
      -webkit-transition: all .35s ease;
      transition: all .35s ease;
      cursor: pointer;
   }

   &--top {
      top: 0;
   }

   &--middle {
      top: 11px;
   }

   &--bottom {
      top: 22px;
   }
}


.navigationBtn.active {
   .navigationBtn--top {
      transform: translateY(11px) translateX(0) rotate(45deg);
      background: #FBF9F9;
      z-index: 99999;
   }

   .navigationBtn--middle {
      opacity: 0;
   }

   .navigationBtn--bottom {
      transform: translateY(-11px) translateX(0) rotate(-45deg);
      background: #FBF9F9;
      z-index: 99999;
   }
}

@media screen and (min-width: 991px) {
   .navigationBtn {
      display: none;
   }
}



@media screen and (max-width: 991px) {
   .navigationBtn {
      display: block;
   }

   .divide__col1 img {
      max-width: 80%;
      margin: auto;
   }

   .navbar__ul {
      display: none;
   }

   .navbar__ul li {
      margin: 15px 0;
   }
}

.open {
   display: flex;
   flex-direction: column;
   height: 100vh;
   width: 100%;
   position: fixed;
   left: 0;
   top: 0;
   background-color: #725d36;
   align-items: center;
   justify-content: center;
}

.overflow {
   overflow: hidden;
   position: relative;
}

.navigation__menu {
   color: #fff;
   font-size: 2em;

   li {
      margin: 0.5em 0;
   }

   li::before {
      background: #f5f5f4;
   }
}


// end navigation
//header
.container-header {
   padding: 1%;
}

.header {
   background: linear-gradient(180deg, #725d36 85%, #c9a768 0%);
   color: #fff;

   &__image {
      background-image: url(../../img/hero.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 200px 180px;
   }

   &__text {
      display: flex;
      align-items: center;
      text-align: center;
      flex-direction: column;

      h1 {
         font-size: 1.6em;
         margin: 1em 0;
         font-family: amster, serif;
         font-weight: 100;
         font-style: normal;
      }

      p {
         font-family: amster, serif;
         font-weight: 400;
         font-style: italic;
      }
   }
}

//end header

// service
.service {
   color: #fff;
   padding: 100px 0;
   text-align: center;
   background-image: url(../../img/bg.png);
   background-position: center center;
   background-size: inherit;
   background-repeat: no-repeat;

   &__text {
      max-width: 50%;
      margin: auto;

      h2 {
         font-size: 2.4em;
         margin-bottom: 0.5em;
         font-family: amster, serif;
         font-weight: 100;
         font-style: normal;
      }

      p {
         font-style: italic;
      }
   }

   &__row {
      max-width: 80%;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 50px;
   }

   &__box {
      flex-basis: 33%;
      padding: 30px;
      font-style: italic;
   }

   &__container {
      padding: 15px;
      background-color: #725d36;
   }

   &__line {
      border: 2px solid #c9a768;
      padding: 60px 15px;
   }

   &__image {
      p {
         margin-top: 0.6em;
         color: #c9a768;
         min-height: 60px;
      }
   }
}

// end service

// divide
.divide {
   background: #725d36;
   width: 100%;

   &__row {
      display: flex;
   }

   &__col1 {
      display: flex;
      align-items: center;
      justify-content: center;
   }

   &__col1,
   &__col2 {
      flex: 1;
   }

   img {
      display: block;
   }
}

// end divide

// people 
.people {
   color: #725d36;
   padding: 100px 0;
   background-image: url(../../img/bg.png);
   background-position: center center;
   background-size: inherit;
   background-repeat: no-repeat;

   &__one {
      max-width: 80%;
      margin: auto;
      display: flex;
   }

   &__one:nth-of-type(2) {
      margin-top: 100px;
   }

   &__photo {
      width: 100%;

      img {
         min-width: 335px;
      }
   }

   &__text {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
   }

   &__top {
      text-align: left;
      margin: 100px 0 0 60px;

      h3 {
         font-family: amster, serif;
         font-weight: 700;
         font-style: normal;
         font-size: 1.8em;
      }

      p {
         font-style: italic;
      }
   }

   &__down {
      display: flex;
      margin-top: 40px;

      p {
         margin-left: 40px;
         margin-top: 15px;
         max-width: 80%;
         font-family: amster, serif;
         font-weight: 300;
         font-style: italic;
      }
   }
}

// end people 

// footer 
.footer {
   background-color: #725d36;
   padding-top: 100px;
   background-image: url(../../img/bg.png);
   color: #fff;
   background-position: center center;
   background-size: inherit;
   background-repeat: no-repeat;

   .title {
      font-size: 1.2em;
      font-family: amster, serif;
      font-weight: 300;
      font-style: italic;
   }

   a::after {
      display: none;
   }

   a:hover {
      text-decoration: underline;
   }

   &__logo {
      text-align: center;
      margin-bottom: 60px;
   }

   &__row {
      max-width: 80%;
      margin: auto;
      display: flex;
   }

   &__text {
      margin-left: 30px;
   }

   .map {
      margin-top: 30px;
      margin-bottom: 150px;
   }

   p {
      line-height: 1.5
   }

   &__col {
      flex: 1;
      margin: 0 50px;
   }

   &__copy {
      background-color: #000000;
      display: flex;
      padding: 0 2%;
      justify-content: space-between;
      font-size: 16px;

      p {
         padding: 10px 0;
         margin: 0;
      }

      a {
         padding: 10px 0;
         margin: 0;
      }
   }
}

// end footer 

// rwd 
@media screen and (max-width: 1500px) {

   .service__row,
   .people__one,
   .footer__row {
      max-width: 90%;
   }
}

@media screen and (max-width: 1349px) {
   .header__image {
      padding: 180px 80px;
   }

   .service__box {
      padding: 15px;
   }
}

@media screen and (max-width: 1268px) {
   body {
      font-size: 20px;
   }
}

@media screen and (max-width: 1120px) {

   .navbar,
   .service__row,
   .people__one,
   .footer__row {
      max-width: 95%;
   }
}

.people .mobile-sign {
   display: none;
}

@media screen and (max-width: 991px) {

   .service,
   .people {
      padding: 70px 0;
   }

   .footer {
      padding-top: 70px;
   }

   .footer__col {
      margin: 0 25px;
   }

   .service__box {
      flex-basis: 50%;
   }

   .people__top {
      margin-top: 40px;
   }

   .people__one:nth-of-type(2) {
      margin-top: 50px;
   }

   .people__down img {
      display: none;
   }

   .people__one {
      flex-direction: column;
   }

   .people__photo {
      justify-content: center;
      display: flex;
   }

   .people .mobile-sign {
      display: block;
      height: 50%;
      margin-top: auto;
      min-width: auto;
      margin-left: 8px;
   }

   .footer__row {
      flex-direction: column;
   }

   .footer .map {
      margin-bottom: 60px;
   }
}

@media screen and (max-width: 668px) {
   body {
      font-size: 18px;
   }

   .navbar,
   .service__row,
   .footer__row {
      max-width: 100%;
      padding-left: 15px;
      padding-right: 15px;
   }

   .people__one {
      max-width: 100%;
   }

   .people {
      padding-left: 15px;
      padding-right: 15px;
   }

   .service,
   .people {
      padding-top: 45px;
      padding-bottom: 45px;
   }

   .service__text {
      max-width: 90%;
   }

   .service__box {
      flex-basis: 100%;
   }

   .service__image p {
      min-height: unset;
   }

   .service__line {
      padding: 40px 15px;
   }

   .header__image {
      padding: 100px 30px;
   }

   .people__down p {
      max-width: 100%;
   }

   .people__top {
      margin-top: 20px;
      margin-left: 50px;
   }

   .people__down {
      margin-top: 0;
   }

   .people__text {
      margin-left: 0;
   }

   .footer {
      padding-top: 45px;
   }

   .footer__logo {
      margin-bottom: 30px;
   }
}

@media screen and (max-width: 568px) {
   body {
      font-size: 15px;
   }

   .header__text img,
   .footer__logo img {
      max-width: 80%;
   }

   .container-nav {
      padding: 10px 0;
   }

   .header__image {
      padding: 60px 10px;
   }

   .people__photo img {
      min-width: unset;
   }

   .divide__row {
      flex-direction: column;
   }

   .divide__col1 img {
      padding: 15px;
   }

   .footer__copy {
      flex-direction: column;
      text-align: center;

      a {
         padding-top: 0;
         padding-bottom: 10px;
      }
   }

   .footer__text {
      margin-left: 15px;
   }

   .footer .map {
      margin-bottom: 30px;
   }

   .people__down p {
      margin-left: 0;
   }

   .footer__col {
      margin: 0;
   }

   .people .mobile-sign {
      display: none;
   }

   .footer__copy {
      font-size: 12px;
   }
}